<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12 md10 text-left>
            <span class="mainHeader">GOLD SCHEME</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 py-6 text-left>
        <v-card
          tile
          elevation="0"
          class="rounded-lg"
          
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4>
            <v-flex xs12 md8 style="font-family: DmSansBold; font-size: 25px"
              >GOLD SCHEME CREATION</v-flex
            >

            <v-flex xs12>
              <v-card
                class="rounded-lg"
                elevation="0"
                :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
                :flat="$route.name == 'Dashboard' ? true : false"
              >
                <v-layout wrap pa-4>
                  <v-flex xs12 md10>
                    <v-layout wrap justify-space-between>
                      <v-flex xs12 md5 pb-3 pb-md-0>
                        <v-flex xs12 md5 pb-1 class="subhead3"
                          >Name of Scheme</v-flex
                        >
                        <v-text-field
                          autofocus
                          v-model="Schemename"
                          outlined
                          color="#b234a9"
                          placeholder="Name of Scheme"
                          dense
                          hide-details
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md5 pb-3 pb-md-0>
                        <v-flex xs12 md5 pb-1 class="subhead3"
                          >Monthly Deposit</v-flex
                        >
                        <v-text-field
                          autofocus
                          v-model="Monthlydeposit"
                          outlined
                          color="#b234a9"
                          placeholder="Monthly Deposit"
                          hint="minimum ₹1000"
                          persistent-hint
                          dense
                          hide-details
                        ></v-text-field>
                      </v-flex>
                      <v-felx xs12 md2 sm2>
                        <v-btn
                          dark
                          class="buttons1"
                          block
                          color="#3F053C"
                          @click="$router.push('/GoldSchemelist')"
                        >
                          Schemes
                        </v-btn>
                      </v-felx>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex xs12 pt-4 pb-1 class="subhead3"
                        >Monthly Interest</v-flex
                      >
                      <v-flex xs12 md5>
                        <v-text-field
                          autofocus
                          v-model="MonthlyInterest"
                          outlined
                          color="#b234a9"
                          placeholder="Monthly Deposit"
                          hint="minimum ₹1000"
                          persistent-hint
                          dense
                          hide-details
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap class="py-4" justify-end>
                      <v-flex xs21 md2 sm2 px-4 class="buttons1">
                        <v-btn class="buttons1" block dark color="#3F053C">
                          Save
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,

      showField1: false,
      showField2: false,
      showField3: false,
      showField4: false,
      row: null,
      Schemename: null,
      Monthlydeposit: null,
      MonthlyInterest: null,
    };
  },
  methods: {
    validateInput() {
      if (!this.email) {
        this.msg = "Please provide email";
        this.showsnackbar = true;
        return;
      } else if (!this.name) {
        this.msg = "Please provide name";
        this.showsnackbar = true;
        return;
      } else if (!this.phone) {
        this.msg = "Please provide phone";
        this.showsnackbar = true;
        return;
      } else if (!this.username) {
        this.msg = "Please provide username";
        this.showsnackbar = true;
        return;
      } else if (!this.address) {
        this.msg = "Please provide address";
        this.showsnackbar = true;
        return;
      } else {
        this.stocklist();
      }
    },
    toggleFields() {
      this.showField1 = !this.showField1;
      this.showField2 = !this.showField2;
      this.showField3 = !this.showField3;
      this.showField4 = !this.showField4;
    },
    // stocklist() {
    //   axios({
    //     url: "/stock/getalllist",
    //     method: "get",
    //     data: {
    //       username: this.username,
    //       email: this.email,
    //       jewelleryName: this.name,
    //       phone: this.phone,
    //       location: this.address,
    //     },
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //     params: {
    //       page: this.currentpage,
    //       limit: this.limit,
    //       from: this.from,
    //       to: this.to,
    //     },

    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status == true) {
    //         this.array = response.data.data;
    //         this.pages = Math.ceil(response.data.totalLength / this.limit);
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
  },
};
</script>
